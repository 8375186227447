<template>
  <v-container
    v-if="showProgress"
  >
    <v-overlay
      :absolute="absolute"
      :opacity="opacity"
      :z-index="zIndex"
      class="overlay-style"
    >
      <div
        class="alert-class"
      >
        <p>{{ processAlert }}</p>
        <v-row>
          <v-progress-linear
            color="#FF3700"
            indeterminate
            rounded
            height="10"
            value="100"
          />
        </v-row>
      </div>
    </v-overlay>
  </v-container>
</template>
<script>

  export default {
    name: 'OverlayAlert',
    props: {
        showProgress: {
            type: Boolean,
            default: false,
        },
        processAlert: {
            type: String,
            default: '',
        },
    },
    data () {
      return {
        absolute: false,
        opacity: 0.6,
        zIndex: 5,
      };
    },
  };
</script>

<style scoped>
.alert-class {
  font-size: 30px;
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  position: absolute;
}
@media (max-width: 767px) {
  .alert-class {
  position: static;
  text-align: center;
  }
}
</style>
