<template>
  <div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-calendar-clock
      </v-icon> Ticker Creation
    </h2>
    <br>
    <v-alert
      v-if="!isOrganizationTickerSettingExist"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      Ticker setting not found against your organization.
    </v-alert>
    <v-stepper
      v-model="createTicker"
      shaped
      class="my-stepper-style"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="createTicker > 1"
          step="1"
          color="#FF3700"
        >
          Create Ticker
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="createTicker > 2"
          step="2"
          color="#FF3700"
        >
          Link Device
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            shaped
            color="#ECEFF1"
          >
            <v-card-text>
              <v-form
                ref="form"
                v-model="isFormValid"
              >
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="tickerTitle"
                        label="Ticker Title*"
                        :rules="[(v) => !!v || 'This field is required']"
                        type="text"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <v-textarea
                        v-model="comment"
                        label="Comment*"
                        :rules="[(v) => !!v || 'This field is required']"
                        filled
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="'required'"
                        name="From datetime"
                      >
                        <v-datetime-picker
                          v-model="start"
                          label="From*"
                          date-format="yyyy-MM-dd"
                          time-format="HH:mm:ss"
                          :text-field-props="{
                            suffix: userTimeZone,
                            errorMessages: errors,
                            class: 'custom-label-color',
                          }"
                          :date-picker-props="{
                            headerColor: '#37474F',
                            min: minStartDate
                          }"
                          :time-picker-props="{
                            headerColor: '#37474F'
                          }"
                        >
                          <template v-slot:dateIcon>
                            <v-icon
                              large
                              color="#37474F"
                            >
                              mdi-calendar
                            </v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon
                              color="#37474F"
                              large
                            >
                              mdi-alarm
                            </v-icon>
                          </template>
                        </v-datetime-picker>
                      </validation-provider>
                    </v-col>
                    <v-col>
                      <v-datetime-picker
                        v-model="end"
                        label="To"
                        :text-field-props="{
                          hint: 'Leave blank if never ends',
                          persistentHint: true,
                          suffix: userTimeZone
                        }"
                        :date-picker-props="{
                          headerColor: '#37474F',
                          min: minEndDate
                        }"
                        :time-picker-props="{
                          headerColor: '#37474F'
                        }"
                      >
                        <template v-slot:dateIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-calendar
                          </v-icon>
                        </template>
                        <template v-slot:timeIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-alarm
                          </v-icon>
                        </template>
                      </v-datetime-picker>
                    </v-col>
                  </v-row>
                  <v-col
                    cols="12"
                    class="text-left"
                  >
                    <v-btn
                      v-if="showScheduleBtn"
                      small
                      dark
                      class="mt-2"
                      color="#FF3700"
                      depressed
                      default
                      rounded
                      @click="showAdvancedSchedule = true"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                      Advanced Schedule
                    </v-btn>
                    <advanced-scheduling v-model="showAdvancedSchedule" />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <advanced-scheduling-list @show-content-form="showContentForm" />
                    <advanced-schedule-edit-before-creation
                      v-if="showScheduleContent"
                      :editvalues="editScheduleContent"
                      :scheduleindex="scheduleIndex"
                      @closed="showScheduleContent = false"
                    />
                  </v-col>
                </v-container>
                <br>
                <p class="text-sm-left">
                  *Indicates required field
                </p>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="red darken-3"
                @click="cancel()"
              >
                cancel
              </v-btn>
              <v-spacer />
              <v-btn
                color="#FF3700"
                :disabled="!isOrganizationTickerSettingExist"
                @click="next()"
              >
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <link-digi-board :ticker-form-details="tickerFormDetails" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <br>
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment-timezone';
  import Constants from 'src/constants';
  import AdvancedScheduling from 'src/views/dashboard/component/schedules/AdvancedScheduling.vue';
  import AdvancedSchedulingList from 'src/views/dashboard/component/schedules/AdvancedSchedulingList.vue';
  import AdvancedScheduleEditBeforeCreation from 'src/views/dashboard/component/schedules/AdvancedScheduleEditBeforeCreation.vue';
  import linkDigiBoard from './LinkDigiBoards';
  import ModuleHelper from 'src/helpers/module-helper';
  import global from 'src/mixins/global';

  export default {
    name: 'CreateNewTicker',
    components: {
      'centre-spinner': spinner,
      AdvancedScheduling,
      AdvancedSchedulingList,
      AdvancedScheduleEditBeforeCreation,
      linkDigiBoard,
    },
    mixins: [global],
    data () {
      return {
        createTicker: 1,
        showAdvancedSchedule: false,
        start: '',
        end: null,
        minStartDate: moment().format('YYYY-MM-DD'),
        loading: false,
        showScheduleContent: false,
        editScheduleContent: {},
        tickerTitle: '',
        comment: '',
        scheduleIndex: 0,
        tickerFormDetails: {
          tickerTitle: '',
          comment: '',
          start: '',
          end: null,
        },
        helper: new ModuleHelper(),
        isFormValid: false,
      };
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.permissionCheck('create-ticker') === false || vm.isPlanExpired === true || vm.helper.isModuleExist(Constants.MODULE_WIDGETS) === false) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      userTimeZone () {
        var Zone = moment.tz.guess();
        var timezone = moment.tz(Zone).zoneAbbr();
        return timezone;
      },
      minEndDate () {
        return moment(this.start).format('YYYY-MM-DD');
      },
     advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      showScheduleBtn () {
        return (this.helper.isSubModuleExistUnderSubModule(Constants.MODULE_WIDGETS, Constants.SUB_MODULE_TICKER, Constants.SUB_MODULE_ADVANCED_SCHEDULING) && this.permissionCheck('create-weekly-schedule'));
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
      organizationTickerSetting () {
       return this.$store.getters['widgets/getOrganizationTickerSetting'];
     },
     isOrganizationTickerSettingExist () {
      if (Object.keys(this.organizationTickerSetting).length > 0) {
        return true;
      }
        return false;
     },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    async mounted () {
      this.$store.dispatch('schedule/clearAdvancedSchedule');
      await this.fetchOrganizationTickerSetting();
    },
    methods: {
      async next () {
        if (this.$refs.form.validate() === false || this.start === '') {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please enter the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.tickerFormDetails.tickerTitle = this.tickerTitle;
        this.tickerFormDetails.comment = this.comment;
        this.tickerFormDetails.start = this.start;
        this.tickerFormDetails.end = this.end;
        setTimeout(() => {
          this.loading = false;
          this.createTicker = 2;
        }, 2000);
      },
      showContentForm (val) {
        this.scheduleIndex = val.index;
        this.editScheduleContent = this.advancedScheduleData.find(
          schedule => schedule.id === val.scheduleId,
        );
        this.showScheduleContent = true;
      },
      cancel () {
        this.$router.push({ name: 'Widgets' });
      },
      async fetchOrganizationTickerSetting () {
       this.loading = true;
       await this.$store.dispatch('widgets/fetchOrganizationTickerSetting');
       this.loading = false;
     },
    },
  };
</script>
<style scoped>
.my-stepper-style {
  background-color: #CFD8DC !important;
  margin-right: 30px;
  margin-left: 30px;
}
.title-style {
  color: #37474F;
  margin-left: 30px !important;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  margin-left: 30px;
  margin-right: 30px;
}
</style>
