<template>
  <section>
    <section>
      <overlay-alert
        :show-progress="showProgress"
        :process-alert="processAlert"
      />
      <v-alert
        v-if="!permissionCheck('read-board-list') && !permissionCheck('read-group')"
        text
        prominent
        type="warning"
        color="red"
        border="left"
        class="authorization-alert"
      >
        You are not authorized to perform this action. Please contact your administrator.
      </v-alert>
      <v-card
        v-if="permissionCheck('read-board-list') || permissionCheck('read-group')"
        shaped
        color="#ECEFF1"
      >
        <br>
        <base-tree-view-without-actions
          :value="treeData"
          class="my-tree-view-style"
        >
          <span slot-scope="{node, path, tree}">
            <input
              id="myCheck"
              type="checkbox"
              :checked="node.$checked"
              @change="onCheckBoxChange(node, tree, path)"
            >
            {{ node.text }}
          </span>
        </base-tree-view-without-actions>
        <v-card-actions>
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              :disabled="!isBoardSelected"
              class="mt-2"
              color="#FF3700"
              @click="createTicker()"
            >
              Create
            </v-btn>
          </v-col>
        </v-card-actions>
        <centre-spinner
          :loading="loading"
        />
      </v-card>
      <br>
      <v-btn
        v-if="!permissionCheck('read-board-list') && !permissionCheck('read-group')"
        color="red darken-3"
        @click="$router.push({ name: 'Widgets' })"
      >
        Back
      </v-btn>
    </section>
  </section>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import { walkTreeData } from 'he-tree-vue';
  import { getDefaultStructure, addMissingBoards } from 'src/helpers/digi-board-structure-helper';
  import global from 'src/mixins/global';
  import moment from 'moment';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';

  export default {
    name: 'SelectDigiBoards',
    components: {
      'centre-spinner': spinner,
      'overlay-alert': OverlayAlert,
    },
    mixins: [global],
    props: {
      tickerFormDetails: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectedDigiBoards: [],
        treeData: [],
        loading: false,
        showProgress: false,
        processAlert: '',
      };
    },
    computed: {
      savedStructure () {
        return this.$store.getters['grouping/getSavedStructure'];
      },
      digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      isBoardSelected () {
        if (this.selectedDigiBoards.length > 0) {
          return true;
        }
        return false;
      },
      ticker () {
        return this.$store.getters['widgets/getTickerContents'];
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('read-group') && await this.fetchDigiBoardSavedStructure();
      this.permissionCheck('read-board-list') && await this.fetchDigiBoards();
      this.showBoardTreeList();
      this.loading = false;
    },
    methods: {
       async fetchDigiBoardSavedStructure () {
        await this.$store.dispatch('grouping/fetchSavedStructure');
      },
      async fetchDigiBoards () {
        await this.$store.dispatch('digiboards/fetchDigiBoards');
      },
      showBoardTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const digiBoards = this.digiBoards;
          this.treeData = getDefaultStructure(digiBoards);
          return;
        }
        const treeData = this.savedStructure.group;
        const digiBoards = this.digiBoards;
        this.treeData = addMissingBoards(treeData, digiBoards);
      },
      onCheckBoxChange (node, tree, path) {
        tree.toggleCheck(node, path);
        const selectedNodes = [];
        tree.walkTreeData((node) => {
          node.$checked && selectedNodes.push(node);
        });
        this.selectedDigiBoards = selectedNodes;
      },
      async linkDigiBoard () {
        this.processAlert = 'Linking...';
        const finalResult = [];
        walkTreeData(this.treeData, (node) => {
          if (node.$checked && node.id !== undefined) {
            finalResult.push(node.id);
          }
        });
        this.$store.dispatch('publish/addSelectedBoardsForPublish', finalResult);
        await this.$store.dispatch('widgets/linkTickerToBoard', {
          tickerId: this.ticker.id,
          digiBoards: { digi_boards: finalResult },
        }).then(response => {
          this.processAlert = '';
          this.showProgress = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Ticker created successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$router.push({ name: 'Widgets' });
          this.$store.dispatch('widgets/clearTickerContents');
        }).catch(() => {
           this.$router.push({ name: 'Widgets' });
        });
      },
      convertToGmt (date) {
        return moment(date).utc();
      },
      async createTicker () {
        if (this.permissionCheck('apply-ticker-to-boards') === false) {
          this.$router.push({ name: 'Widgets' });
          this.$store.dispatch('alert/onAlert', {
            message: '"Apply ticker to boards" permission not found. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
          this.showProgress = true;
          this.processAlert = 'Creating...';
          const schedules = [];
          for (const advancedSchedule of this.advancedScheduleData) {
            schedules.push(advancedSchedule.id);
          }
          await this.$store.dispatch('widgets/createTicker', {
            title: this.tickerFormDetails.tickerTitle,
            comment: this.tickerFormDetails.comment,
            start: this.convertToGmt(this.tickerFormDetails.start),
            end: this.tickerFormDetails.end !== null ? this.convertToGmt(this.tickerFormDetails.end) : null,
            schedules: schedules,
          }).then(response => {
            this.$store.dispatch('schedule/clearAdvancedSchedule');
            this.linkDigiBoard();
          }).catch(() => {
            this.$router.push({ name: 'Widgets' });
          });
      },
    },
  };
</script>
<style scoped>
.my-tree-view-style {
  margin-left: 30px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
